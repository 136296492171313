import React, {useEffect} from "react"
import {navigate} from "gatsby"

export default function Logout(props){

    useEffect(()=>navigate("/login"), [])


    return (
        <React.Fragment></React.Fragment>
    )

}